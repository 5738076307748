import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
    <h1>Politique de Confidentialité</h1>
    <p>Dernière mise à jour en juin 2024.
        <br />  <br />
        Bienvenue sur MonAMbassade, fourni par Desire Boutchoue, autres partenaires connexes. Cette Politique de Confidentialité de MonAmbassade ("Politique") décrit les informations que nous recueillons auprès de vous lorsque vous utilisez le Site, les applications mobiles et les services associés (ensemble, les "Services"), ainsi que la manière dont nous utilisons, traitons et divulguons ces informations. Nous pouvons compléter cette Politique avec d'autres avis. Nous pouvons également publier des déclarations de confidentialité supplémentaires pour certaines parties des Services. En soumettant des informations personnelles via nos Services, vous consentez expressément au transfert de vos données personnelles vers nos serveurs aux États-Unis pour notre collecte, utilisation et divulgation conformément à cette Politique.</p>
    <h2>Informations Que Nous Recueillons</h2>
    <p>Lorsque vous créez un compte et utilisez notre Service, nous recueillons les types d'informations suivants auprès de vous :
        <br />  <br />
        1. Nom ;  <br />
        2. Informations de contact telles que votre adresse e-mail et numéro de téléphone ;  <br />
        3. Toute autre information, telle que des avis, du contenu et une biographie, que vous nous fournissez.  <br />
        Si vous créez un compte en utilisant, ou connectez votre compte à, un compte de service de réseau social (par exemple, Twitter ou Facebook), nous pouvons également recueillir les informations fournies par ce service de réseau social, y compris par exemple : votre nom ; adresse e-mail ; date de naissance ; localisation géographique ; centres d'intérêt ; photo de profil ; sexe ; réseaux ; identifiant utilisateur ; liste d'amis ; et toute information que vous avez rendue publique sur ce compte de réseau social.
        <br />  <br />
        Nous recueillons automatiquement des informations sur la manière dont vous utilisez nos services, par exemple, les pages que vous avez consultées. Nous recueillons également certaines informations techniques sur votre appareil, y compris votre adresse de protocole Internet, les informations de géolocalisation, le type de votre navigateur, la langue et les informations d'identification, votre système d'exploitation et la version de l'application, les types d'appareils, le modèle et le fabricant de l'appareil, les identifiants des appareils, et le type et la version du système d'exploitation de votre appareil.
        <br />  <br />
        Nous utilisons également des cookies, des balises Web et des informations d'URL pour recueillir des informations concernant la date et l'heure de votre visite et les informations que vous avez recherchées et consultées. Les cookies sont de petits morceaux d'information qu'un site web envoie au disque dur de votre ordinateur lorsque vous consultez un site web. Nous pouvons utiliser des cookies de session (qui expirent une fois que vous fermez votre navigateur web) et des cookies persistants (qui restent généralement sur votre ordinateur jusqu'à ce que vous les supprimiez) pour vous offrir une expérience plus personnelle et interactive sur notre Site. Les balises Web sont des images numériques utilisées pour enregistrer des informations sur les Services ou dans nos e-mails. Nous utilisons des balises Web pour gérer les cookies, compter les visites et savoir quelle publicité fonctionne ou non. Nous utilisons également des balises Web pour savoir si vous ouvrez ou agissez sur nos e-mails.
        <br />  <br />
        D'autres, y compris des prestataires de services d'analyse tiers et des partenaires publicitaires, peuvent également recueillir des informations personnellement identifiables sur vos activités en ligne au fil du temps et sur différents sites web lorsque vous utilisez nos Services, comme décrit dans cette Politique de Confidentialité. Cette Politique ne s'applique pas et nous ne sommes pas responsables de ces autres parties. Les services d'analyse tiers peuvent utiliser des cookies et des balises Web via notre Site (par exemple, Google Analytics) pour nous fournir des informations sur la manière dont vous utilisez et interagissez avec notre Site. Nous vous encourageons à consulter les politiques de confidentialité de ces tiers pour en savoir plus sur vos choix concernant les informations qu'ils collectent auprès de vous.</p>
    <h2>Partage De Vos Informations</h2>
    <p>Les Services DeViine peuvent vous permettre de vous connecter et de partager vos actions, commentaires, contenu, profil et informations publiquement ou avec d'autres personnes. Soyez attentif à vos propres besoins en matière de confidentialité lorsque vous choisissez avec qui vous connecter et ce que vous partagez et rendez public. Nous ne pouvons pas contrôler la confidentialité ou la sécurité des informations que vous choisissez de rendre publiques ou de partager avec d'autres.
        <br />  <br />
        Les Services peuvent également vous offrir la possibilité de partager certaines informations de votre compte DeViine avec des services de réseaux sociaux comme Facebook, Google ou Twitter. Nous ne sommes pas responsables de l'utilisation ou du re-partage par d'autres de vos informations une fois qu'elles sont rendues publiques. Si vous ne souhaitez pas que vos informations soient rendues publiques, vous ne devez pas utiliser les Services DeViine de cette manière et/ou vous devez ajuster vos paramètres de confidentialité sur le service de réseau social concerné en conséquence. Nous ne sommes pas responsables et nous ne contrôlons pas les pratiques de confidentialité de ces services de réseaux sociaux. Veuillez consulter la politique de confidentialité applicable pour obtenir des informations sur la manière dont ils utilisent vos informations.</p>
    <h2>Comment Nous Utilisons Vos Informations</h2>
    <p>Nous utilisons généralement vos informations pour :
        <br />  <br />
        Faciliter la création et sécuriser votre compte sur les Services ;
        Vous identifier en tant qu'utilisateur dans notre système ;
        Fournir, personnaliser et améliorer les Services ;
        Communiquer avec vous au sujet de votre utilisation des Services ;
        Développer de nouveaux produits et services ;
        Personnaliser la publicité que vous voyez et recommander du contenu ;
        Satisfaire vos demandes ;
        Envoyer des newsletters, enquêtes, offres et documents promotionnels liés aux Services et à d'autres fins de marketing de DeViine en utilisant vos informations de contact ;
        Protéger, enquêter et prévenir les activités potentiellement frauduleuses, non autorisées ou illégales ;
        Protéger nos droits et les droits des autres utilisateurs ; et
        Comme décrit dans cette Politique de Confidentialité ou dans les avis que nous vous avons fournis.
        Nous pouvons également utiliser vos informations pour vérifier votre localisation géographique. Nous pouvons utiliser les données de votre localisation géographique pour personnaliser notre Service, recommander du contenu, déterminer si l'information que vous avez demandée est disponible dans votre localisation.</p>
    <h2>Comment Nous Divulguons Vos Informations</h2>
    <p>Nous pouvons partager vos informations comme suit :
        <br />  <br />
        1. Nous pouvons partager vos informations personnelles avec votre consentement ou à votre demande.
        2. Nous pouvons également partager vos informations avec d'autres qui fournissent des services en notre nom.
        3. Nous pouvons divulguer vos informations si nous croyons que nous sommes tenus de le faire par la loi, ou pour se conformer à une ordonnance du tribunal, une citation à comparaître judiciaire ou autre, ou un mandat gouvernemental.
        4. Nous pouvons également divulguer vos informations si nous croyons que cela est approprié ou nécessaire pour prévenir toute responsabilité, ou utilisation frauduleuse, abusive ou illégale, ou pour protéger DeViine et nos Services ; ou tout droit, propriété ou sécurité personnelle de DeViine ou d'autres personnes.
        5. Dans le cas où DeViine serait ou pourrait être acquis par ou fusionné avec une autre entreprise ou impliqué dans toute autre transaction commerciale (ou négociation d'une transaction commerciale) impliquant la vente ou le transfert de tout ou partie de notre entreprise ou de nos actifs, nous pouvons transférer ou céder vos informations dans le cadre ou en relation avec la transaction. Enfin, en cas d'insolvabilité, de faillite ou de mise sous séquestre, les informations peuvent être transférées comme un actif commercial.
        Nous pouvons également partager des données agrégées et anonymisées avec nos partenaires, annonceurs et autres tiers.</p>
    <h2>Sécurité</h2>
    <p>MonAmbassade prend des mesures commercialement raisonnables pour aider à protéger vos informations contre la perte, l'utilisation abusive et l'accès non autorisé, ou la divulgation. Cependant, aucune entreprise ne peut entièrement prévenir les risques de sécurité. Bien que nous nous efforcions de protéger vos informations personnelles, nous ne pouvons pas garantir leur sécurité absolue. Pour vous aider à vous protéger et à protéger vos informations, choisissez un mot de passe unique pour nos Services et n'utilisez pas un mot de passe que vous utiliseriez sur tout autre site web ou service en ligne.</p>
    <h2>Résolution des Conflits</h2>
    <p>Si vous pensez que DeViine n'a pas adhéré à cette Déclaration, veuillez contacter DeViine par e-mail à support@deviine.com. Nous ferons de notre mieux pour répondre à vos préoccupations. Si vous estimez que votre plainte n'a pas été traitée de manière complète, nous vous invitons à nous le faire savoir pour une enquête plus approfondie.</p>
    <h2>Choix d'Information</h2>
    <p>Vous pouvez vous désinscrire de la réception des e-mails promotionnels de DeViine en suivant les instructions contenues dans ces e-mails. Si vous vous désinscrivez, nous pouvons toujours vous envoyer des e-mails non promotionnels, tels que des e-mails concernant vos comptes ou nos relations commerciales en cours. Vous pouvez également envoyer des demandes concernant vos informations personnelles, y compris les modifications de vos préférences de contact, les modifications ou suppressions de vos informations ou du contenu que vous publiez, et les demandes de désactivation du partage de vos informations personnelles avec des tiers en envoyant un e-mail à support@deviine.com. Veuillez noter que la suppression de vos informations ou de votre contenu n'assure pas une suppression complète ou exhaustive du contenu ou des informations publiées sur les Services.
<br /> <br />
Lorsque vous visitez le Site, nous et d'autres vous offrons les choix suivants concernant l'utilisation des mécanismes de suivi, y compris le suivi de vos activités en ligne au fil du temps et sur différents sites web et services en ligne par des tiers. La plupart des navigateurs Web sont configurés pour accepter les cookies par défaut. Si vous le souhaitez, vous pouvez généralement choisir de configurer votre navigateur pour supprimer les cookies et rejeter les cookies de notre Site ou de tiers. Si vous choisissez de supprimer les cookies ou de rejeter les cookies, cela pourrait affecter certaines fonctionnalités ou services de notre Site. Pour vous désinscrire du suivi Web Google Analytics pour certains navigateurs, vous pouvez télécharger le module complémentaire de navigateur pour la désactivation de Google Analytics. Vous pouvez vous désinscrire d'autres cookies pouvant être présents sur le Site en suivant les instructions sur la page de désactivation de Google. Cependant, bien que nous et d'autres vous offrions des choix comme décrit dans cette politique, il existe de nombreuses façons dont les signaux du navigateur Web et d'autres mécanismes similaires peuvent indiquer votre choix de désactiver le suivi, et notre Site peut ne pas être au courant ou honorer tous les mécanismes.
<br /> <br />
Vous avez peut-être le droit de savoir quelles informations personnelles DeViine détient à votre sujet et de corriger toute inexactitude. Veuillez adresser toute demande de ce type par e-mail à support@deviine.com ou par l'un des autres moyens énumérés ci-dessous.</p>
<h2>Modifications et Mises à Jour de cette Politique de Confidentialité</h2>
<p>De temps à autre, nous pouvons réviser la Politique. Pour vous aider à rester informé de tout changement, nous indiquons la date de la dernière mise à jour de la Politique de Confidentialité ci-dessus.</p>
<h2>Informations de Contact</h2>
<p>Veuillez contacter MonAmbassade pour toute question ou commentaire concernant cette Politique, vos informations, nos pratiques de divulgation à des tiers, ou vos choix de consentement.
<br /> <br /> MonAmbassade <br />
Ankara <br />
  Turquie<br />
Téléphone :+90 5392462904 <br />
Email : info@angeboutchoue.com</p>

</div>
  );
}

export default App;
